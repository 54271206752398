import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { GET_PROFILE_LIST } from 'utils/utils';

export const getProfileListData = createAsyncThunk(
  'profileList/getProfileListData',
  async (params,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");    
    const config = {
        method: "get",
        url: GET_PROFILE_LIST,
        params:params||{},        
        headers: {
          Authorization: token,
        },
      };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

const initialState = {
  List: [],
  loading: 'idle',
  error: null,
};

export const profileListSlice = createSlice({
  name: 'profileList',
  initialState,
  reducers: {
    removeProfileListState:(state,action)=>{
      state.List= []
      state.loading= 'idle'
      state.error= null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileListData.pending, (state, action) => {
          state.loading = 'loading'
      })
      .addCase(getProfileListData.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.List = action.payload?.data?.data
          state.error = null
      })
      .addCase(getProfileListData.rejected, (state, action) => {
          state.loading = 'failed'
          state.List = []
          state.error = action.payload
      })
  },
})

export const { removeProfileListState } = profileListSlice.actions;
export default profileListSlice.reducer;