import { addDevicePairingsRemoveStateData } from 'features/device/addDevicePairingSlice';
import { addDevicePairingsData } from 'features/device/addDevicePairingSlice';
import { getAreaListData } from 'features/facility/areaListSlice';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'

const AddDevicePairingModel = ({ open, toggle, isEdit, editDevice, notify }) => {

    const [moduleList, setModuleList] = React.useState([]);
    const [selectedModuleList, setSelectedModuleList] = React.useState([]);
    const [pairingList, setPairingList] = React.useState([]);

    const areaList = useSelector((state) => state.areaList);
    const devicePairings = useSelector((state) => state.devicePairings);
    const addDevicePairings = useSelector((state) => state.addDevicePairings);

    const dispatch = useDispatch();

    // const pairingList = [
    //     "pairing1",
    //     "pairing2",
    //     "pairing3",
    //     "pairing4",
    // ];

    // const moduleList = editDevice?.device?.device_modules?.map((item) => {
    //     return { value: item?.id, label: item?.module_name };
    // });
React.useEffect(() => {
    if(devicePairings.loading==="succeeded"){
        const list = devicePairings.List?.map((item) => {
            const cultivation_area = {value: item?.cultivation_area?.id, label: item?.cultivation_area?.cultivation_name};
            const device_module = item?.device_module?.id ? {value: item?.device_module?.id, label: item?.device_module?.module_name} : null;
            return{
                cultivation_area,
                device_module
            }
        });
        setPairingList(list);

        const ModuleList=[];
         list.map((item) => {
           return item?.device_module?.value ? ModuleList.push(item.device_module.value) : null;
        });
        setSelectedModuleList(ModuleList);
}
},[devicePairings.loading]);


React.useEffect(() => {
    if(devicePairings.loading==="succeeded"){
        
        const list = editDevice?.device?.device_modules?.map((item) => {
                return { value: item?.id, label: item?.module_name, isDisabled: selectedModuleList.includes(item?.id)};
            });
        setModuleList(list);
    }
},[selectedModuleList]);

React.useEffect(() => {
    if(addDevicePairings.loading==="succeeded"){
        notify(addDevicePairings.data?.message, false);
        toggle();
        dispatch(addDevicePairingsRemoveStateData());
    }else if(addDevicePairings.loading==="failed"){
        notify(addDevicePairings?.error?.data?.message, true);
        dispatch(addDevicePairingsRemoveStateData());
    }
},[addDevicePairings.loading]);



const handlePairing=(value,isAdded,index)=>{
    if(isAdded){
        setSelectedModuleList([...selectedModuleList, value?.value]);
        setPairingList([...pairingList.slice(0,index), {...pairingList[index], device_module: value}, ...pairingList.slice(index+1)]);
    }else{
        setSelectedModuleList(selectedModuleList.filter((item) => item !== value?.value));
        setPairingList([...pairingList.slice(0,index), {...pairingList[index], device_module: null}, ...pairingList.slice(index+1)]);
    }
};



const handleSubmit = async()=>{
    const pair = pairingList.map((item) => {
        return {cultivation_area_id: item?.cultivation_area?.value, device_module_id: item?.device_module?.value};
    })
    dispatch(addDevicePairingsData({id: editDevice?.id, pairing: pair}));
}



    const closeBtn = (
        <button className="close text-white" onClick={toggle} type="button">
            &times;
        </button>
    );

    return (
        <>
            <Modal returnFocusAfterClose={true} isOpen={open} backdrop={true} className="facility-modal">
                <ModalHeader className="facility-modal-header text-white w-100" close={closeBtn}>
                    <p className="pr-2 mb-3 h4 text-white text-capitalize">
                        Pairing Cultivation Areas
                    </p>
                </ModalHeader>
                <ModalBody className="facility-modal-body position-relative module-pair-model-body">
                    {/* <Row>
                        <Col>
                            <FormGroup>
                                <Label for="facility" className="mb-0">facility</Label>
                                <Select
                                 id="facility"
                                 name="facility"
                                 type='select'
                                 className="bg-dark basic-single"
                                 classNamePrefix="select"
                                 isSearchable={true}
                                //  value={{ value: item.pairing, label: item.pairing }}
                                 options={[...facilityList.List?.map(facility => ({ value: facility?.id, label: facility?.facility_name }))]}
                                 onChange={(e) => {
                                     getAreaList(e.value)
                                 }}/>
                            </FormGroup>
                        </Col>
                    </Row> */}
                    {devicePairings.List?.map((item, index) => (
                        <>
                            <hr className='border-white d-lg-none' />
                            <Row>
                                <Col md={12} lg={6}>
                                    <FormGroup>
                                        <Label for="serial" className="mb-0">Cultivation Area</Label>
                                        <Input
                                            id="serial"
                                            name="serial"
                                            type="text"
                                            className="bg-dark text-white-50"
                                            value={item.cultivation_area.cultivation_name}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                {/* <Col sm={12} md={12} lg={4} >
                                <FormGroup>
                                    <Label for="Pairing" className="mb-0">Pairing</Label>
                                    <Select
                                        id="Pairing"
                                        name="Pairing"
                                        type='select'
                                        className="bg-dark basic-single"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        // value={{ value: item.pairing, label: item.pairing }}
                                        options={[...pairingList.map(pair => ({ value: pair, label: pair }))]}
                                        onChange={(e) => {
                                        }}
                                    />
                                </FormGroup>
                            </Col> */}
                                <Col md={12} lg={6}>
                                    <FormGroup>
                                        <Label for="serial" className="mb-0">Module</Label>
                                        <Select
                                            id="Pairing"
                                            name="Pairing"
                                            type='select'
                                            className="bg-dark basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            isClearable={true}
                                            value={pairingList?.[index]?.device_module}
                                            options={[...moduleList]}
                                            onChange={(value) => {
                                                const isAdded = value !== null;
                                                handlePairing(value||pairingList?.[index]?.device_module, isAdded,index);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    ))}
                </ModalBody>
                <ModalFooter className="facility-modal-footer">
                    <Button className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button" onClick={toggle}>
                        Close
                    </Button>
                    <Button className="btn-light border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="submit"
                    onClick={handleSubmit}
                    disabled={addDevicePairings.loading==="loading"}
                    >{
                            (addDevicePairings.loading==="loading")
                             ? <Spinner size="sm">Save Device Pairing</Spinner>:
                            <>Save Device Pairing</>}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AddDevicePairingModel