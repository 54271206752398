import {
  faArchway,
  faDroplet,
  faGear,
  faLightbulb,
  faMapLocationDot,
  faPenToSquare,
  faServer,
  faSun,
  faTemperatureThreeQuarters,
  faTowerBroadcast,
  faTrashCan,
  faUser,
  faWarehouse,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import NotificationAlert from "react-notification-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import AddAlarmModel from "./components/AllModals/AddAlarmModel";
import { getFacilityListData } from "features/facility/facilityListSlice";
import { getAlarmTableData } from "features/alarm/alarmTableSlice";
import { editAlarmData } from "features/alarm/editAlarmSlice";
import { editAlarmRemoveStateData } from "features/alarm/editAlarmSlice";
import { deleteAlarmRemoveStateData } from "features/alarm/deleteAlarmSlice";
import { deleteAlarmData } from "features/alarm/deleteAlarmSlice";
import { removeDevicePairingsStateData } from "features/device/devicePairingsSlice";
import { getDeviceListData } from "features/device/deviceListSlice";
import { getAreaListData } from "features/facility/areaListSlice";
import { getDevicePairingsData } from "features/device/devicePairingsSlice";
import PaginationNav from "./components/PaginationNav";
import { removeDeviceListState } from "features/device/deviceListSlice";
import classNames from "classnames";

const Alarm = () => {
  const [openedCollapseOne, setOpenedCollapseOne] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [alert, setAlert] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editAlarm, setEditAlarm] = React.useState(false);
  const notificationAlertRef = React.useRef(null);

  const tableData = useSelector((state) => state.alarmTable);

  const EditAlarm = useSelector((state) => state.editAlarm);

  const alarmsDelete = useSelector((state) => state.deleteAlarm);
  const addAlarms = useSelector((state) => state.addAlarm);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAlarmTableData({ page: 1 }));
  }, []);

  const refScrollUp = useRef(null);
  const handleCollapseReset = () => {
    setOpenedCollapseOne([...openedCollapseOne.map(() => false)]);
  };

  const handleCollapse = (index) => {
    setOpenedCollapseOne([
      ...openedCollapseOne.slice(0, index),
      !openedCollapseOne[index],
      ...openedCollapseOne.slice(index + 1),
    ]);
  };

  const handleDeleteAlarm = async (alarms) => {
    // API call
    hideAlert();
    await dispatch(await deleteAlarmData({ ...alarms }));
    await dispatch(await getAlarmTableData({ page: 1 }));
  };

  React.useEffect(() => {
    if (alarmsDelete.loading === "succeeded") {
      successDelete();
      setLoading(false);
      dispatch(deleteAlarmRemoveStateData());
    }
    if (alarmsDelete.loading === "failed") {
      hideAlert();
      notify(alarmsDelete?.error?.data?.message, true);
      setLoading(false);
      dispatch(deleteAlarmRemoveStateData());
    }
    if (alarmsDelete.loading === "loading") {
      setLoading(true);
    }
  }, [alarmsDelete.loading]);

  React.useEffect(() => {
    if (tableData.loading === "loading") {
      setLoading(true);
    }
    if (tableData.loading === "failed") {
      notify(tableData?.error?.data?.message, true);
      setLoading(false);
    }
    window.scrollTo(0, 0);
  }, [tableData.loading]);

  React.useEffect(() => {
    if (
      addAlarms.loading === "succeeded" ||
      alarmsDelete.loading === "succeeded"
    ) {
      handleCollapseReset();
    }
  }, [addAlarms.loading, alarmsDelete.loading]);

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Alarm hasa been deleted.
      </ReactBSAlert>
    );
  };

  const warningWithConfirmMessage = (alarms) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteAlarm(alarms)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this alarm!
      </ReactBSAlert>
    );
  };

  const notify = (msg, isError) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            {msg}
            {/* Welcome to <b>Black Dashboard React</b> - a beautiful premium admin
            for every web developer. */}
          </div>
        </div>
      ),
      type: isError ? "danger" : "success",
      icon: isError
        ? "tim-icons icon-alert-circle-exc"
        : "tim-icons icon-check-2",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen(!open);

  const handleOpenEditModal = async (alarms) => {
    await dispatch(getFacilityListData());
    await dispatch(getDeviceListData({ facility_id: alarms?.facility_id }));
    await dispatch(getDevicePairingsData({ id: alarms?.user_device_id }));
    setEditAlarm(alarms);
    setIsEdit(true);
    toggle();
  };

  const handleOpenAddModal = () => {
    setIsEdit(false);
    dispatch(removeDevicePairingsStateData());
    dispatch(removeDeviceListState());
    dispatch(getFacilityListData());
    setEditAlarm(null);
    toggle();
  };

  const handlePageChange = async (page) => {
    handleCollapseReset();
    await dispatch(getAlarmTableData({ page }));
  };

  // const dataTable = [
  //   {
  //     module_name: "Silverfin Fish Hatchery",
  //     area: "Auto Climate Pro-1",
  //     deviceModel: "Auto Climate Pro V4",
  //     card: [
  //       {
  //         title: "Temperature",
  //         data: {
  //           max: "25",
  //           min: "23",
  //         }
  //       },
  //       {
  //         title: "Humidity",
  //         data: {
  //           max: "90",
  //           min: "70",
  //         }
  //       },
  //       {
  //         title: "CO2",
  //         data: {
  //           max: "1000",
  //           min: "0",
  //         }
  //       },
  //       {
  //         title: "Configurations",
  //         data: {
  //           "enable IR": true,
  //           "Co2 By Light": true,
  //           "Dynamic light": true,
  //         }
  //       },
  //     ]
  //   },
  //   {
  //     module_name: "Silverfin Fish Hatchery 2",
  //     area: "Auto Climate Pro-2",
  //     deviceModel: "Auto Climate Pro V5",
  //     card: [
  //       {
  //         title: "Temperature",
  //         data: {
  //           max: "25",
  //           min: "23",
  //         }
  //       },
  //       {
  //         title: "Humidity",
  //         data: {
  //           max: "90",
  //           min: "70",
  //         }
  //       },
  //       {
  //         title: "CO2",
  //         data: {
  //           max: "1000",
  //           min: "0",
  //         }
  //       },
  //       {
  //         title: "Configurations",
  //         data: {
  //           "enable IR": true,
  //           "Co2 By Light": true,
  //           "Dynamic light": true,
  //         }
  //       },
  //     ]
  //   },
  // ]

  //   React.useEffect(() => {
  //     if (EditAlarm.loading === "succeeded") {
  //         notify(EditAlarm.data?.message,false);
  //         dispatch(editAlarmRemoveStateData());
  //         dispatch(getAlarmTableData({page:tableData.page_information.current_page}))
  //     } else if (EditAlarm.loading === "failed") {
  //         notify(EditAlarm?.error?.data?.message,true);
  //         dispatch(editAlarmRemoveStateData());
  //     }
  // }, [EditAlarm.loading]);

  const editCheckbox = async (key, value, id) => {
    await dispatch(
      editAlarmData({ data: { [key]: value.toString() }, id: id })
    );
  };

  const IconsList = {
    Temperature: {
      right: faTemperatureThreeQuarters,
      left: <>&deg;C</>,
      classNames:
        "info-icon text-center icon-primary d-flex align-items-center justify-content-center",
    },
    Humidity: {
      right: faDroplet,
      left: <>%</>,
      classNames:
        "info-icon text-center icon-danger d-flex align-items-center justify-content-center",
    },
    CO2: {
      right: faWind,
      left: <>PPM</>,
      classNames:
        "info-icon text-center icon-success d-flex align-items-center justify-content-center",
    },
    Brightness: {
      right: faSun,
      left: <>%</>,
      classNames:
        "info-icon text-center icon-warning d-flex align-items-center justify-content-center",
    },
    Configurations: {
      right: faGear,
      left: <></>,
      classNames:
        "info-icon text-center icon-light d-flex align-items-center justify-content-center",
    },
    Light: {
      right: faLightbulb,
      left: <></>,
      classNames:
        "info-icon text-center icon-warning d-flex align-items-center justify-content-center",
    },
  };

  const isAlarmOn = (name, item) => {
    if (name === "Temperature") {
      if (item?.cards?.[item?.cards?.length - 1]?.data?.is_temp) {
        return "On";
      } else {
        return "Off";
      }
    }
    if (name === "Humidity") {
      if (item?.cards?.[item?.cards?.length - 1]?.data?.is_humidity) {
        return "On";
      } else {
        return "Off";
      }
    }
    if (name === "CO2") {
      if (item?.cards?.[item?.cards?.length - 1]?.data?.is_ppm) {
        return "On";
      } else {
        return "Off";
      }
    }
  };

  return (
    <>
      {}
      <div className="content " id="alarmsTable">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        {alert}
        <Card ref={refScrollUp}>
          <CardHeader headerTag="h6" className="bg-transparent border-bottom-0">
            <div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
              <div className="numbers">
                <p className=" px-2 mb-0 h4 text-white text-capitalize">
                  existing Alarm
                </p>
              </div>
              <Button
                className="btn-simple border-white text-white d-flex add-btn justify-content-center align-items-center text-capitalize"
                color="primary"
                type="button"
                onClick={handleOpenAddModal}
              >
                <i className="tim-icons icon-simple-add pr-2" />
                Add New Alarm
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {tableData?.page_Data?.map((item, index) => (
              <>
                <Card className="facility-card" key={item?.id + index + "Card"}>
                  <CardHeader>
                    <Row>
                      <Col sm={12} md={3} lg={3}>
                        <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                          <div className="mb-3 d-flex justify-content-start align-items-center w-fit ">
                            <FontAwesomeIcon
                              icon={faWarehouse}
                              size="sm"
                              className="mb-1"
                              style={{ color: "#ffffff" }}
                            />
                            <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">
                              Facility
                            </p>
                          </div>
                          <div
                              role="button"
                              aria-expanded={openedCollapseOne}
                              href="#pablo"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              className='d-flex justify-content-start align-items-center w-fit pointer-event'
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenedCollapseOne(!openedCollapseOne[index]);
                                handleCollapse(index);
                              }}
                            >
                              {openedCollapseOne[index]
                                ? <i className="tim-icons icon-minimal-down pl-2 fa-rotate-180 animation-transition-general d-none d-md-block d-sm-none" />
                                : <i className="tim-icons icon-minimal-down pr-2 animation-transition-general d-none d-md-block d-sm-none" />
                              }
                              <span className=" mb-0 text-white-50 fs-2 font-weight-light ">{item?.facility_name}</span> {" "}
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={2} lg={3}>
                        <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                          <div className="mb-3 d-flex justify-content-start align-items-center w-fit ">
                            <FontAwesomeIcon
                              icon={faMapLocationDot}
                              size="sm"
                              className="mb-1"
                              style={{ color: "#ffffff" }}
                            />
                            <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">
                              Cultivation Area
                            </p>
                          </div>
                          <div className="d-flex justify-content-start align-items-center w-fit pointer-event">
                            <span className=" mb-0 text-white-50 fs-3 font-weight-normal ">
                              {item?.cultivation_name}
                            </span>{" "}
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={2} lg={2}>
                        <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                          <div className="mb-3 d-flex justify-content-start align-items-center w-fit ">
                            <FontAwesomeIcon
                              icon={faTowerBroadcast}
                              size="sm"
                              className="mb-1"
                              style={{ color: "#ffffff" }}
                            />
                            <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">
                              Controller
                            </p>
                          </div>
                          <div className="d-flex justify-content-start align-items-center w-fit pointer-event">
                            <span className=" mb-0 text-white-50 fs-3 font-weight-normal ">
                              {item?.device_name}
                            </span>{" "}
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={3} lg={2}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-3 d-flex justify-content-start align-items-center w-fit">
                            <FontAwesomeIcon icon={faServer} rotation={180} size="sm" className='mb-0' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-2 font-weight-normal">Module</p>
                            </div>
                          <div className="d-flex justify-content-start align-items-center w-fit pointer-event">
                            <span className=" mb-0 text-white-50 fs-3 font-weight-normal ">
                              {item?.module_name}
                            </span>{" "}
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={2} lg={2}>
                        <div className="d-none d-md-flex justify-content-around align-items-center h-100">
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            size="xl"
                            className="px-2 cursor-pointer"
                            style={{ color: "#ffffff" }}
                            onClick={(e) => {
                              handleOpenEditModal(item);
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            size="xl"
                            className="px-2 cursor-pointer"
                            style={{ color: "#ffffff" }}
                            onClick={() => warningWithConfirmMessage(item)}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={0} className="d-md-none">
                        <dov className="d-flex w-100 justify-content-between align-items-center mb-1">
                          <Button
                            className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize"
                            color="primary"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenedCollapseOne(!openedCollapseOne[index]);
                              handleCollapse(index);
                            }}
                          >
                            {openedCollapseOne[index] ? (
                              <i className="tim-icons icon-minimal-down px-2 fa-rotate-180 animation-transition-general d-block d-lg-none mb-1" />
                            ) : (
                              <i className="tim-icons icon-minimal-down px-2 animation-transition-general d-block d-lg-none " />
                            )}
                            Parameters
                          </Button>
                          <div className="d-flex justify-content-around align-items-center">
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              size="xl"
                              className="px-2 cursor-pointer"
                              style={{ color: "#ffffff" }}
                              onClick={(e) => {
                                handleOpenEditModal(item);
                              }}
                            />
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              size="xl"
                              className="px-2 cursor-pointer"
                              style={{ color: "#ffffff" }}
                              onClick={() => warningWithConfirmMessage(item)}
                            />
                          </div>
                        </dov>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pb-0 px-0">
                    <Collapse role="tabpanel" isOpen={openedCollapseOne[index]}>
                      <div className="py-3 d-flex flex-wrap facility-collapse-div">
                        {item.cards.map((cardData, index) => {
                          return cardData?.title !== "Configurations" ? (
                            <Col>
                              <Card className="card-stats dashboard-card profile-card">
                                <CardBody>
                                  <div className="d-flex w-100 h-100 p-1 flex-column">
                                    <div className="w-100 d-flex justify-content-between align-items-center">
                                      <div
                                        className={
                                          IconsList[cardData?.title]?.classNames
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            IconsList[cardData?.title]?.right
                                          }
                                          size="2xl"
                                          style={{ color: "#ffffff" }}
                                        />
                                      </div>
                                      <div className="numbers">
                                        <p className=" px-2 mb-0 h4 fs-3 font-weight-normal text-white text-left">
                                          {cardData?.title === "CO2" ? (
                                            <>
                                              {" "}
                                              Co<sub>2</sub>
                                            </>
                                          ) : (
                                            cardData?.title
                                          )}
                                        </p>
                                      </div>
                                      <div>
                                        <p className=" px-1 mb-0 h3 text-white">
                                          {" "}
                                          {IconsList[cardData?.title]?.left}
                                        </p>
                                      </div>
                                    </div>
                                    {
                                      <>
                                        <div className="d-flex w-100 h-100 p-2 justify-content-between align-items-center">
                                          <div className="d-flex flex-column mr-1">
                                            <p className="h4 mt-2 text-white text-capitalize mb-0">
                                              {" "}
                                              {cardData?.data?.max || 0}{" "}
                                            </p>
                                            <p className="h5 mt-2 text-white text-capitalize mb-0">
                                              {" "}
                                              Max{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex flex-column">
                                            <p className="h4 mt-2 text-white text-capitalize mb-0">
                                              {" "}
                                              {cardData?.data?.min || 0}{" "}
                                            </p>
                                            <p className="h5 mt-2 text-white text-capitalize mb-0">
                                              {" "}
                                              Min{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="d-flex w-100 h-100 p-2 justify-content-between align-items-center">
                                          <p className="h5 mt-2 text-white text-capitalize mb-0">
                                            {" "}
                                            Alarm :{" "}
                                            {isAlarmOn(
                                              cardData?.title,
                                              item
                                            )}{" "}
                                          </p>
                                        </div>
                                      </>
                                    }
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ) : (
                            <></>
                          );
                        })}
                      </div>
                    </Collapse>
                  </CardBody>
                </Card>
              </>
            ))}

            <>
              {tableData.page_Data && tableData.page_Data.length > 0 ? (
                tableData.page_information?.last_page > 1 ? (
                  <PaginationNav
                    page_information={tableData?.page_information}
                    setPage={handlePageChange}
                  />
                ) : (
                  <></>
                )
              ) : (
                <p className="h5">
                  There no existing alarm here, press "Add new alarm" to create
                  first one.
                </p>
              )}
            </>
          </CardBody>
        </Card>
      </div>
      {open ? (
        <AddAlarmModel
          open={open}
          toggle={toggle}
          isEdit={isEdit}
          editAlarm={editAlarm}
          notify={notify}
        />
      ) : (
        <></>
      )}
      {tableData?.loading === "loading" && (
        <div className="loading-container">
          <div className="d-flex justify-content-center loading-div">
            <Spinner size="xl" />
          </div>
        </div>
      )}
    </>
  );
};

export default Alarm;
