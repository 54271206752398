import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { GET_ALL_NOTIFICATION } from 'utils/utils';

export const getNotificationListData = createAsyncThunk(
  'notificationList/getNotificationListData',
  async (_,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");    
    const config = {
        method: "get",
        url: GET_ALL_NOTIFICATION,
        headers: {
          Authorization: token,
        },
      };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

const initialState = {
  List: [],
  loading: 'idle',
  error: null,
};

export const notificationListSlice = createSlice({
  name: 'notificationList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationListData.pending, (state, action) => {
          state.loading = 'loading'
      })
      .addCase(getNotificationListData.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.List = action.payload?.data?.data
          state.error = null
      })
      .addCase(getNotificationListData.rejected, (state, action) => {
          state.loading = 'failed'
          state.List = []
          state.error = action.payload
      })
  },
})


export default notificationListSlice.reducer;