
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "features/user/userSlice";
import NotificationModel from "views/components/AllModals/NotificationModel";
import { getNotificationListData } from "features/notification/notificationListSlice";

const AdminNavbar = (props) => {
  const userData = useSelector((state) => state.userData);
  const notificationList = useSelector((state) => state.notificationList);

  const dispatch = useDispatch();
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalSearch, setModalSearch] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setModalSearch(!modalSearch);
  };
  const navigate =useNavigate();
  const handleLogout = () => {
    secureLocalStorage.clear();
    navigate("/login");
  };

  React.useEffect(() =>{
    !userData.userData && dispatch(getUserData());
  },[userData.userData]);
  
  
  
  
  
  
  React.useEffect(() =>{
    setInterval(() => {
      dispatch(getNotificationListData());
    },1*60*1000);
  },[]);


  
  return (
    <>
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: props.location?.pathname?.indexOf("full-screen-map") === -1,
        })}
        expand="lg"
      >
        <Container fluid className="d-flex flex-row align-items-center justify-content-between px-0">
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209599"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button> */}
            <Nav className="flex-row align-items-center justify-content-center" navbar>
                <Button
                  color="link"
                  className="w-fit m-0"
                  data-target="#NotificationModal"
                  data-toggle="modal"
                  id="Notification-button"
                  onClick={toggleModalSearch}
                >
                <i className="tim-icons icon-bell-55 bell-icon text-white m-0" />
               {notificationList?.List?.length > 0 && <div className="notification  d-block " />}
                </Button>
                  <p className="px-2 text-nowrap mb-0 d-none d-md-block">{userData.userData?.data?.name}</p>
                  <Button
                  color="link"
                  className="w-fit p-1 m-0"
                  data-target="#logout-button"
                  id="logout-button"
                  onClick={handleLogout}
                >
                    <i className="tim-icons icon-button-power logout-icon m-0"/>
                </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="logout-button"
                        >
                          Logout
                        </UncontrolledTooltip>
            </Nav>
        </Container>
      </Navbar>
      <NotificationModel toggle={toggleModalSearch} open={modalSearch} />
      {/* <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal> */}
    </>
  );
};

export default AdminNavbar;
