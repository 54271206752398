
import { faArchway, faDroplet, faGear, faLightbulb, faMapLocationDot, faPenToSquare, faServer, faSun, faTemperatureThreeQuarters, faTowerBroadcast, faTrashCan, faUser, faWarehouse, faWind } from '@fortawesome/free-solid-svg-icons';
import NotificationAlert from "react-notification-alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Collapse, CustomInput, FormGroup, Input, Label, Progress, Row, Spinner } from 'reactstrap'
import ReactBSAlert from "react-bootstrap-sweetalert";
// import ProfilesAddAndEdit from './components/AllModals/ProfilesAddAndEdit';
import { useDispatch, useSelector } from 'react-redux';
import AddProfileModel from './components/AllModals/AddProfileModel';
import { getDeviceListData } from 'features/device/deviceListSlice';
import { getProfileTableData } from 'features/profile/profileTableSlice';
import { deleteProfileRemoveStateData } from 'features/profile/deleteProfileSlice';
import { deleteProfileData } from 'features/profile/deleteProfileSlice';
import PaginationNav from './components/PaginationNav';
import { editProfileData } from 'features/profile/editProfileSlice';
import { editProfileRemoveStateData } from 'features/profile/editProfileSlice';
// import { getProfilesTableData } from 'features/profiles/profilesTableSlice';
// import { deleteProfilesData } from 'features/profiles/deleteProfiles';
// import PaginationNav from './components/PaginationNav';

const Profiles = () => {
  const [openedCollapseOne, setOpenedCollapseOne] = React.useState([
    false, false, false, false, false, false, false, false, false, false,
  ]);
  const [alert, setAlert] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editProfiles, setEditProfiles] = React.useState(false);
  const notificationAlertRef = React.useRef(null);

  const tableData = useSelector((state) => state.profileTable);

  const EditProfile = useSelector((state) => state.editProfile);
  const addProfile = useSelector((state) => state.addProfile);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getProfileTableData({ page: 1 }));
  }, [])

  const refScrollUp = useRef(null);
  const handleCollapseReset = () => {
    setOpenedCollapseOne([...openedCollapseOne.map(() => false)]);
  }
  const handleCollapse = (index) => {
    setOpenedCollapseOne([...openedCollapseOne.slice(0, index), !openedCollapseOne[index], ...openedCollapseOne.slice(index + 1)]);
  }
  const profilesDelete = useSelector((state) => state.deleteProfile);

  const handleDeleteProfiles = async (profiles) => {
    // API call
    hideAlert();
    await dispatch(await deleteProfileData({ ...profiles }));
    await dispatch(await getProfileTableData({ page: 1 }));
  }


  React.useEffect(() => {
    if (profilesDelete.loading === "succeeded") {
      successDelete();
      setLoading(false);
      dispatch(deleteProfileRemoveStateData());
    }
    if (profilesDelete.loading === "failed") {
      hideAlert();
      notify(profilesDelete?.error?.data?.message, true);
      setLoading(false);
      dispatch(deleteProfileRemoveStateData());
    }
    if (profilesDelete.loading === "loading") {
      setLoading(true);
    }
  }, [profilesDelete.loading]);

  React.useEffect(() => {

    if (tableData.loading === "loading") {
      setLoading(true);
    }
    if (tableData.loading === "failed") {
      notify(tableData?.error?.data?.message, true);
      setLoading(false);
    }
    window.scrollTo(0, 0);

  }, [tableData.loading]);

  
  React.useEffect(()=>{
    if(addProfile.loading==="succeeded"||profilesDelete.loading==="succeeded"){
      handleCollapseReset();
    }
  },[addProfile.loading, profilesDelete.loading])



  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Profile hasa been deleted.
      </ReactBSAlert>
    );
  };


  const warningWithConfirmMessage = (profiles) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteProfiles(profiles)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this profile!
      </ReactBSAlert>
    );
  };

  const notify = (msg,isError) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            {msg}
            {/* Welcome to <b>Black Dashboard React</b> - a beautiful premium admin
            for every web developer. */}
          </div>
        </div>
      ),
      type: isError?"danger":"success",
      icon:isError? "tim-icons icon-alert-circle-exc":"tim-icons icon-check-2",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };


  const hideAlert = () => {
    setAlert(null);
  };

  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen(!open);


  const handleOpenEditModal = (profiles) => {
    setEditProfiles(profiles);
    setIsEdit(true);
    toggle();
  }

  const handleOpenAddModal = () => {
    setIsEdit(false);
    dispatch(getDeviceListData());
    setEditProfiles(null);
    toggle();
  }

  const handlePageChange = async (page) => {
    handleCollapseReset();
    await dispatch(getProfileTableData({page}));
  }

  const dataTable = [
    {
      profile: "Silverfin Fish Hatchery",
      deviceName: "Auto Climate Pro-1",
      deviceModel: "Auto Climate Pro V4",
      card: [
        {
          title: "Temperature",
          day: "25",
          night: "23",
        },
        {
          title: "Humidity",
          day: "90",
          night: "70",
        },
        {
          title: "CO2",
          day: "1000",
          night: "0",
        },
        {
          title: "Light Brightness",
          day: "100",
          night: "0",
        },
        {
          title: "configuration set",
          "enable IR": true,
          "Co2 By Light": true,
          "Dynamic light": true,
        },
        {
          title: "Light settings",
          start: "06:00",
          off: "20:00"
        },
      ]
    },
    {
      profile: "Silverfin Fish Hatchery 2",
      deviceName: "Auto Climate Pro-2",
      deviceModel: "Auto Climate Pro V5",
      card: [
        {
          title: "Temperature",
          day: "25",
          night: "23",
        },
        {
          title: "Humidity",
          day: "90",
          night: "70",
        },
        {
          title: "CO2",
          day: "1000",
          night: "0",
        },
        {
          title: "Light Brightness",
          day: "100",
          night: "0",
        },
        {
          title: "configuration set",
          "enable IR": true,
          "Co2 By Light": true,
          "Dynamic light": true,
        },
        {
          title: "Light settings",
          start: "06:00",
          off: "20:00"
        },
      ]
    },
  ]


  React.useEffect(() => {
    if (EditProfile.loading === "succeeded") {
        notify(EditProfile.data?.message,false);
        dispatch(editProfileRemoveStateData());
        dispatch(getProfileTableData({page:tableData.page_information.current_page}))
    } else if (EditProfile.loading === "failed") {
        notify(EditProfile?.error?.data?.message,true);
        dispatch(editProfileRemoveStateData());
    }
}, [EditProfile.loading]);



const editCheckbox = async(key,value,id)=>{
 await dispatch(editProfileData({ data:{[key]:value}, id: id }));
}

  const IconsList = {
    "Temperature": { right: faTemperatureThreeQuarters, left: <>&deg;C</>, classNames:"info-icon text-center icon-primary d-flex align-items-center justify-content-center" },
    "Humidity": { right: faDroplet, left: <>%</>, classNames:"info-icon text-center icon-danger d-flex align-items-center justify-content-center" },
    "CO2": { right: faWind, left: <>PPM</>, classNames:"info-icon text-center icon-success d-flex align-items-center justify-content-center" },
    "Brightness": { right: faSun, left: <>%</>, classNames:"info-icon text-center icon-warning d-flex align-items-center justify-content-center" },
    "Configurations": { right: faGear, left: <></>, classNames:"info-icon text-center icon-light d-flex align-items-center justify-content-center" },
    "Light": { right: faLightbulb, left: <></>, classNames:"info-icon text-center icon-warning d-flex align-items-center justify-content-center" },
  }

  return (
    <>
      { }
      <div className='content ' id='profilesTable' >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        {alert}
        <Card ref={refScrollUp}>
          <CardHeader headerTag="h6" className="bg-transparent border-bottom-0">
            <div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
              <div className="numbers">
                <p className=" px-2 mb-0 h4 text-white text-capitalize">existing Profiles</p>
              </div>
              <Button className="btn-simple border-white text-white d-flex add-btn justify-content-center align-items-center text-capitalize" color="primary" type="button" onClick={handleOpenAddModal}>
                <i className="tim-icons icon-simple-add pr-2" />Add New Profile
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {
              tableData?.page_Data?.map((item, index) =>
              (
                <>
                  <Card className="facility-card" key={item?.name + index + "Card"}>
                    <CardHeader>
                      <Row>
                        <Col md={12} lg={4}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-3 d-flex justify-content-start align-items-center w-fit">
                              <FontAwesomeIcon icon={faUser} size="xs" className='mb-0' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-2 font-weight-normal"> Profile Name</p>
                            </div>
                            <div
                              role="button"
                              aria-expanded={openedCollapseOne}
                              href="#pablo"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              className='d-flex justify-content-start align-items-center w-fit pointer-event'
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenedCollapseOne(!openedCollapseOne[index]);
                                handleCollapse(index);
                              }}
                            >
                              {openedCollapseOne[index]
                                ? <i className="tim-icons icon-minimal-down pl-2 fa-rotate-180 animation-transition-general d-none d-lg-block d-md-none" />
                                : <i className="tim-icons icon-minimal-down pr-2 animation-transition-general d-none d-lg-block d-md-none" />
                              }
                              <span className=" mb-0 text-white-50 fs-2 font-weight-light ">{item?.profile_name}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col md={12} lg={3}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-3 d-flex justify-content-start align-items-center w-fit ">
                              <FontAwesomeIcon icon={faServer} rotation={180} size="sm" className='mb-0' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">Device Name</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-fit pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal ">{item?.device_name}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col md={12} lg={3}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-3 d-flex justify-content-start align-items-center w-fit ">
                              <FontAwesomeIcon icon={faTowerBroadcast} size="sm" className='mb-0' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">Device Model</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-fit pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal ">{item?.device_model}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col md={12} lg={2}>
                          <div className="d-none d-lg-flex justify-content-around align-items-center h-100" >
                            <FontAwesomeIcon icon={faPenToSquare} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={(e) => { handleOpenEditModal(item) }} />
                            <FontAwesomeIcon icon={faTrashCan} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={() => warningWithConfirmMessage(item)} />
                          </div>
                        </Col>
                        <Col md={12} lg={0} className='d-lg-none'>
                          <dov className="d-flex w-100 justify-content-between align-items-center mb-1">
                            <Button className="btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenedCollapseOne(!openedCollapseOne[index]);
                                handleCollapse(index);
                              }}>
                              {openedCollapseOne[index]
                                ? <i className="tim-icons icon-minimal-down px-2 fa-rotate-180 animation-transition-general d-block d-lg-none mb-1" />
                                : <i className="tim-icons icon-minimal-down px-2 animation-transition-general d-block d-lg-none " />
                              }
                              Parameters
                            </Button>
                            <div className="d-flex justify-content-around align-items-center" >
                              <FontAwesomeIcon icon={faPenToSquare} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={(e) => { handleOpenEditModal(item) }} />
                              <FontAwesomeIcon icon={faTrashCan} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={() => warningWithConfirmMessage(item)} />
                            </div>
                          </dov>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className='pb-0 px-0'>
                      <Collapse role="tabpanel" isOpen={openedCollapseOne[index]}>
                        <div className='py-3 d-flex flex-wrap facility-collapse-div'>

                          {item.cards.map((cardData, index) => (
                            <Col>
                              <Card className="card-stats dashboard-card profile-card">
                                <CardBody>
                                  <div className="d-flex w-100 h-100 p-1 flex-column">
                                    <div className="w-100 d-flex justify-content-between align-items-center">
                                      <div className={IconsList[cardData?.title]?.classNames}>
                                        <FontAwesomeIcon icon={IconsList[cardData?.title]?.right} size="2xl" style={{ color: "#ffffff", }} />
                                      </div>
                                      <div className="numbers">
                                        <p className=" px-2 mb-0 h4 text-white text-left">{cardData?.title === "CO2" ? <> Co<sub>2</sub></> : cardData?.title}</p>
                                      </div>
                                      <div>
                                        <p className=" px-1 mb-0 h3 text-white"> {IconsList[cardData?.title]?.left}</p>
                                      </div>
                                    </div>
                                    {(cardData?.data?.night?.toString()?.length && cardData?.data?.day?.toString()?.length)
                                      ? (
                                        <>
                                      <div className="d-flex w-100 h-100 p-2 justify-content-between align-items-center">
                                        <div className='d-flex flex-column mr-1'>
                                          <p className="h4 mt-2 text-white text-capitalize mb-0">  {cardData?.data?.day} </p>
                                          <p className="h5 mt-2 text-white text-capitalize mb-0">  Day </p>
                                        </div>
                                        <div className='d-flex flex-column'>
                                          <p className="h4 mt-2 text-white text-capitalize mb-0"> {cardData?.data?.night} </p>
                                          <p className="h5 mt-2 text-white text-capitalize mb-0">  Night </p>
                                        </div>
                                      </div>
                                      {cardData?.data?.period && <div className='mt-2 d-flex w-100 border border-white-50 px-2 rounded-lg  justify-content-between align-items-center'>
                                          <p className="h5 my-2 text-white text-capitalize text-nowrap mb-0"> Period </p>
                                          <p className="h5 my-2 text-white text-capitalize text-nowrap mb-0"> {cardData?.data?.period} min </p>
                                          </div>}
                                      </>
                                      )
                                      : (cardData?.data?.on?.toString()?.length && cardData?.data?.off?.toString()?.length)
                                        ? (
                                        <>
                                        <div className="d-flex w-100 h-100 p-2 justify-content-between align-items-center">
                                          <div className='d-flex flex-column mr-1'>
                                            <p className="h4 mt-2 text-white text-capitalize mb-0">  {cardData?.data?.on} </p>
                                            <p className="h5 mt-2 text-white text-capitalize mb-0">  Day </p>
                                          </div>
                                          <div className='d-flex flex-column'>
                                            <p className="h4 mt-2 text-white text-capitalize mb-0"> {cardData?.data?.off} </p>
                                            <p className="h5 mt-2 text-white text-capitalize mb-0">  Night </p>
                                          </div>
                                        </div>
                                         {cardData?.data?.ppfd && <div className='mt-2 d-flex w-100 border border-white-50 px-2 rounded-lg  justify-content-between align-items-center'>
                                          <p className="h5 my-2 text-white text-capitalize text-nowrap mb-0"> PPFD </p>
                                          <p className="h5 my-2 text-white text-capitalize text-nowrap mb-0"> {cardData?.data?.ppfd}  </p>
                                          </div>}
                                        </>)
                                        : (cardData?.data?.start?.toString()?.length && cardData?.data?.stop?.toString()?.length)
                                          ? (<>
                                          <div className="d-flex w-100 h-100 p-2 justify-content-between align-items-center">
                                            <div className='d-flex flex-column mr-1'>
                                              <p className="h4 mt-2 text-white text-capitalize text-nowrap mb-0">  {cardData?.data?.start} </p>
                                              <p className="h5 mt-2 text-white text-capitalize text-nowrap mb-0">  Light start </p>
                                            </div>
                                            <div className='d-flex flex-column'>
                                              <p className="h4 mt-2 text-white text-capitalize text-nowrap mb-0"> {cardData?.data?.stop} </p>
                                              <p className="h5 mt-2 text-white text-capitalize text-nowrap mb-0">  Light stop </p>
                                            </div>
                                          </div>
                                          </>
                                          )
                                          : (<Row className='d-flex flex-column p-2 pt-3'>
                                            {Object.keys(cardData?.data).map((key, index) => {
                                              return key !== "title" && (
                                                <Col>
                                                  <FormGroup className=' mb-2'>
                                                    {/* <Input id={key} type="checkbox" checked={cardData[key]} /> */}
                                                    <CustomInput
                                                      type="switch"
                                                      id={key + index}
                                                      name={key}
                                                      label={key}
                                                      value={cardData?.data[key]}
                                                      checked={cardData?.data[key]}
                                                    onChange={e => {
                                                      editCheckbox(key,e.target.checked,item.id);
                                                    }}
                                                    />
                                                    {/* <Label for={key} className='mb-0 text-white' >{key === "Co2 By Light" ? <> Co<sub>2</sub> By Light</> : key + ""}</Label> */}
                                                  </FormGroup>
                                                </Col>
                                              )
                                            })}
                                          </Row>)}
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}

                        </div>
                      </Collapse>
                    </CardBody>
                  </Card>
                </>
              )
              )
            }

            <>
              {(tableData.page_Data && tableData.page_Data.length > 0) ?
           tableData.page_information?.last_page > 1 ? <PaginationNav page_information={tableData?.page_information} setPage={handlePageChange}/>:<></>
            :<p className='h5'>There no existing profile here, press "Add new profile" to create first one.</p>
            }
            </>
          </CardBody>

        </Card>
      </div>
      {open ?
        <AddProfileModel
          open={open}
          toggle={toggle}
          isEdit={isEdit}
          editProfile={editProfiles}
          notify={notify}
        /> : <></>
      }
      {(tableData?.loading === "loading") &&
        <div className="loading-container">
          <div className="d-flex justify-content-center loading-div"><Spinner size="xl" /></div>
        </div>
      }
    </>
  )
}

export default Profiles

