
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { REGISTER } from "utils/utils";
import { faGaugeHigh, faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudversify } from "@fortawesome/free-brands-svg-icons";

const Register = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({});
  const [value, setValue] = React.useState({
    name: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    return function cleanup() {
      document.body.classList.toggle("register-page");
    };
  });
  const handleRegister = async () => {
    setLoading(true);

    const signInPayload = {
      name: value.name,
      email: value.email,
      password: value.password,
    };

    const config = {
      method: "post",
      url: REGISTER,
      data: signInPayload,
    };
    // axios(config)
    //   .then((response) => {
    //     if ((response.data.success = true)) {
    //       setMessage("");
    // navigate("/login");
    //   }
    // })
    // .catch((error) => {
    //   console.log("error===>",error);
    //   setMessage(error?.response?.data?.message);
    setLoading(false);
    // });
  };

  return (
    <>
      <div className="wrapper wrapper-full-page">
        <div className="full-page login-page">
          {/* <div className="content">
            <Container>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form className="form">
                  <Card className="card-login card-white">
                    <CardHeader className="mb-5">
                      <img alt="..." src={require("assets/img/logo_black_.png")} className="card-img-top p-3" />
                    </CardHeader>
                    <CardBody>
                    <InputGroup
                          className={classnames({
                            "input-group-focus": state.nameFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Full Name"
                            type="text"
                            name="name"
                            disabled={loading}
                            onFocus={(e) => setState({ ...state, nameFocus: true })}
                            onBlur={(e) => setState({ ...state, nameFocus: false })}
                            onChange={(e) =>
                              setValue({ ...value, name: e.target.value })
                            }
                          />
                        </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": state.emailFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          name="email"
                          disabled={loading}
                          onFocus={(e) => setState({ ...state, emailFocus: true })}
                          onBlur={(e) => setState({ ...state, emailFocus: false })}
                          onChange={(e) =>
                            setValue({ ...value, email: e.target.value })
                          }
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": state.passFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          name="password"
                          type="password"
                          disabled={loading}
                          invalid={message?.length > 0 ? true : false}
                          onFocus={(e) => setState({ ...state, passFocus: true })}
                          onBlur={(e) => setState({ ...state, passFocus: false })}
                          onChange={(e) =>
                            setValue({ ...value, password: e.target.value })
                          }
                        />
                      </InputGroup>
                      <p className="text-danger">{message}</p>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        className="mb-3"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRegister();
                        }}
                        size="lg"
                        disabled={
                          loading ||
                          value.email.trim() === "" ||
                          value.password === ""
                        }
                      >
                        {loading && <Spinner size="sm">Loading...</Spinner>}
                        {loading ? "Loading..." : "Sign Up"}
                      </Button>
                      <div className="pull-left">
                        <h6>
                          <Link to="/login" className="link footer-link">
                            Already have an account
                          </Link>
                        </h6>
                      </div>
                      <div className="pull-right">
                        <h6>
                          <Link to="#" className="link footer-link">
                            Forgot password
                          </Link>
                        </h6>
                      </div>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div> */}
          <div className="content h-100dvh full-page d-flex justify-content-center align-items-center">
            <Container className="w-100">
              <Row>
                <Col className="ml-auto mb-2" md="6">
                  <div className="w-100 text-center">
                    <img alt="..." src={require("assets/img/logo__.png")} className="card-img-top p-3 register-logo-img" />
                  </div>
                  <div className="info-area info-horizontal mt-2">
                    <div className="icon icon-warning">
                      {/* <i className="tim-icons icon-wifi" /> */}
                      <FontAwesomeIcon icon={faWifi} size="2xl" className="mr-3 mt-1 ml-1"  />
                    </div>
                    <div className="description">
                      <h3 className="info-title mb-2 mt-4">Stay Connected</h3>
                      <p className="description">
                        Stay in touch with your farm operations anytime, anywhere.
                        Our platform ensures seamless communication and updates, keeping you informed of every detail in real time.
                      </p>
                    </div>
                  </div>
                  <div className="info-area info-horizontal">
                    <div className="icon icon-primary">
                      {/* <i className="tim-icons icon-triangle-right-17" /> */}
                      <FontAwesomeIcon icon={faGaugeHigh} size="2xl" className="mr-3 mt-1 ml-1"  />
                    </div>
                    <div className="description">
                      <h3 className="info-title mb-2 mt-4">Monitoring</h3>
                      <p className="description">
                        Easily monitor environmental parameters, equipment status, and overall system performance.
                        Gain valuable insights to optimize productivity and efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="info-area info-horizontal">
                    <div className="icon icon-info">
                      {/* <i className="tim-icons icon-trophy" /> */}
                      <FontAwesomeIcon icon={faCloudversify} size="2xl" className="mr-3 mt-1 ml-1"  />
                    </div>
                    <div className="description">
                      <h3 className="info-title mb-2 mt-4">Control from Anywhere</h3>
                      <p className="description">
                        Take full control of your farm remotely. With our user-friendly interface,
                        you can adjust settings, manage resources, and ensure smooth operations no matter where you are.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto" md="6">
                  <Card className="card-register card-white">
                    <CardHeader>
                      <div className="d-flex justify-content-start align-items-center px-2 pt-5">
                      <img alt="..." src={require("assets/img/logo__black_.png")} className="register-card-img-top" /> 
                      <h3 className=" mb-0 register-card-title"> Create your account </h3>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Form className="form">
                        <InputGroup
                          className={classnames({
                            "input-group-focus": state.nameFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Full Name"
                            type="text"
                            name="name"
                            disabled={loading}
                            onFocus={(e) => setState({ ...state, nameFocus: true })}
                            onBlur={(e) => setState({ ...state, nameFocus: false })}
                            onChange={(e) =>
                              setValue({ ...value, name: e.target.value })
                            }
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            "input-group-focus": state.emailFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            name="email"
                            disabled={loading}
                            onFocus={(e) =>
                              setState({ ...state, emailFocus: true })
                            }
                            onBlur={(e) =>
                              setState({ ...state, emailFocus: false })
                            }
                            onChange={(e) =>
                              setValue({ ...value, email: e.target.value })
                            }
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            "input-group-focus": state.passFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            name="password"
                            disabled={loading}
                            onFocus={(e) => setState({ ...state, passFocus: true })}
                            onBlur={(e) => setState({ ...state, passFocus: false })}
                            onChange={(e) =>
                              setValue({ ...value, password: e.target.value })
                            }
                          />
                        </InputGroup>
                        <p className="text-danger">{message}</p>
                        <FormGroup check className="text-left">
                          <div className="pull-left">
                            <h6>
                              <Link to="/login" className="link footer-link">
                                Already have an account
                              </Link>
                            </h6>
                          </div>
                          <div className="pull-right">
                            <h6>
                              <Link to="#" className="link footer-link">
                                Forgot password
                              </Link>
                            </h6>
                          </div>
                        </FormGroup>
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Button
                        className="btn-round"
                        color="primary"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRegister();
                        }}
                        size="lg"
                        disabled={
                          loading ||
                          value.email.trim() === "" ||
                          value.name.trim() === "" ||
                          value.password === ""
                        }
                      >
                        {loading && <Spinner size="sm">Loading...</Spinner>}
                        {loading ? "Loading..." : "Sign Up"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );


  // return (
  //   <>
  //     {/* <div className="wrapper wrapper-full-page">
  //       <div className="full-page register-page">
  //         <div className="content">
  //           <Container>
  //             <Row> */}
  //               {/* <Col className="ml-auto" md="5">
  //             <div className="info-area info-horizontal mt-5">
  //               <div className="icon icon-warning">
  //                 <i className="tim-icons icon-wifi" />
  //               </div>
  //               <div className="description">
  //                 <h3 className="info-title">Marketing</h3>
  //                 <p className="description">
  //                   We've created the marketing campaign of the website. It was
  //                   a very interesting collaboration.
  //                 </p>
  //               </div>
  //             </div>
  //             <div className="info-area info-horizontal">
  //               <div className="icon icon-primary">
  //                 <i className="tim-icons icon-triangle-right-17" />
  //               </div>
  //               <div className="description">
  //                 <h3 className="info-title">Fully Coded in HTML5</h3>
  //                 <p className="description">
  //                   We've developed the website with HTML5 and CSS3. The client
  //                   has access to the code using GitHub.
  //                 </p>
  //               </div>
  //             </div>
  //             <div className="info-area info-horizontal">
  //               <div className="icon icon-info">
  //                 <i className="tim-icons icon-trophy" />
  //               </div>
  //               <div className="description">
  //                 <h3 className="info-title">Built Audience</h3>
  //                 <p className="description">
  //                   There is also a Fully Customizable CMS Admin Dashboard for
  //                   this product.
  //                 </p>
  //               </div>
  //             </div>
  //           </Col> */}
  //               {/* <Col md="2" />
  //               <Col className="mr-auto" md="8">
  //                 <Card className="card-register card-white">
  //                   <CardHeader>
  //                     <CardImg
  //                       alt="..."
  //                       src={require("assets/img/card-primary.png")}
  //                     />
  //                     <CardTitle tag="h4">Register</CardTitle>
  //                   </CardHeader>
  //                   <CardBody>
  //                     <Form className="form">
  //                       <InputGroup
  //                         className={classnames({
  //                           "input-group-focus": state.nameFocus,
  //                         })}
  //                       >
  //                         <InputGroupAddon addonType="prepend">
  //                           <InputGroupText>
  //                             <i className="tim-icons icon-single-02" />
  //                           </InputGroupText>
  //                         </InputGroupAddon>
  //                         <Input
  //                           placeholder="Full Name"
  //                           type="text"
  //                           disabled={loading}
  //                           onFocus={(e) => setState({ ...state, nameFocus: true })}
  //                           onBlur={(e) => setState({ ...state, nameFocus: false })}
  //                           onChange={(e) =>
  //                             setValue({ ...value, name: e.target.value })
  //                           }
  //                         />
  //                       </InputGroup>
  //                       <InputGroup
  //                         className={classnames({
  //                           "input-group-focus": state.emailFocus,
  //                         })}
  //                       >
  //                         <InputGroupAddon addonType="prepend">
  //                           <InputGroupText>
  //                             <i className="tim-icons icon-email-85" />
  //                           </InputGroupText>
  //                         </InputGroupAddon>
  //                         <Input
  //                           placeholder="Email"
  //                           type="email"
  //                           disabled={loading}
  //                           onFocus={(e) =>
  //                             setState({ ...state, emailFocus: true })
  //                           }
  //                           onBlur={(e) =>
  //                             setState({ ...state, emailFocus: false })
  //                           }
  //                           onChange={(e) =>
  //                             setValue({ ...value, email: e.target.value })
  //                           }
  //                         />
  //                       </InputGroup>
  //                       <InputGroup
  //                         className={classnames({
  //                           "input-group-focus": state.passFocus,
  //                         })}
  //                       >
  //                         <InputGroupAddon addonType="prepend">
  //                           <InputGroupText>
  //                             <i className="tim-icons icon-lock-circle" />
  //                           </InputGroupText>
  //                         </InputGroupAddon>
  //                         <Input
  //                           placeholder="Password"
  //                           type="password"
  //                           disabled={loading}
  //                           onFocus={(e) => setState({ ...state, passFocus: true })}
  //                           onBlur={(e) => setState({ ...state, passFocus: false })}
  //                           onChange={(e) =>
  //                             setValue({ ...value, password: e.target.value })
  //                           }
  //                         />
  //                       </InputGroup>
  //                       <p className="text-danger">{message}</p>
  //                       <FormGroup check className="text-left">
  //                         <Label check>
  //                           <Input type="checkbox" />
  //                           <span className="form-check-sign" />I agree to the{" "}
  //                           <a href="#pablo" onClick={(e) => e.preventDefault()}>
  //                             terms and conditions
  //                           </a>
  //                           .
  //                         </Label>
  //                       </FormGroup>
  //                     </Form>
  //                   </CardBody>
  //                   <CardFooter>
  //                     <Button
  //                       className="btn-round"
  //                       color="primary"
  //                       href="#pablo"
  //                       onClick={(e) => {
  //                         e.preventDefault();
  //                         handleRegister();
  //                       }}
  //                       size="lg"
  //                       disabled={
  //                         loading ||
  //                         value.email.trim() === "" ||
  //                         value.name.trim() === "" ||
  //                         value.password === ""
  //                       }
  //                     >
  //                       {loading && <Spinner size="sm">Loading...</Spinner>}
  //                       {loading ? "Loading..." : "Get Started"}
  //                     </Button>
  //                   </CardFooter>
  //                 </Card>
  //               </Col>
  //               <Col md="2" />
  //             </Row>
  //           </Container>
  //         </div>
  //       </div>
  //     </div> */}
  //   </>
  // );
};

export default Register;