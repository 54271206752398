
import { faA, faArchway, faClock, faCloud, faDroplet, faGear, faMapLocationDot, faPenToSquare, faServer, faSun, faTemperatureThreeQuarters, faTowerBroadcast, faTrashCan, faUser, faWarehouse, faWind } from '@fortawesome/free-solid-svg-icons';
import NotificationAlert from "react-notification-alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Collapse, FormGroup, Input, Label, Progress, Row, Spinner } from 'reactstrap'
import ReactBSAlert from "react-bootstrap-sweetalert";
// import ScheduleAddAndEdit from './components/AllModals/ScheduleAddAndEdit';
import { useDispatch, useSelector } from 'react-redux';
import ScheduleAddAndEdit from './components/AllModals/ScheduleAddAndEdit';
import { getFacilityListData } from 'features/facility/facilityListSlice';
import { getScheduleTableData } from 'features/schedule/scheduleTableSlice';
import { deleteScheduleData } from 'features/schedule/deleteScheduleSlice';
import { deleteScheduleRemoveStateData } from 'features/schedule/deleteScheduleSlice';
import PaginationNav from './components/PaginationNav';
import { getDeviceListData } from 'features/device/deviceListSlice';
import { getProfileListData } from 'features/profile/profileListSlice';
import { getAreaListData } from 'features/facility/areaListSlice';
import dayjs from 'dayjs';
import { removeDeviceListState } from 'features/device/deviceListSlice';
import { removeProfileListState } from 'features/profile/profileListSlice';
// import { getScheduleTableData } from 'features/schedule/scheduleTableSlice';
// import { deleteScheduleData } from 'features/schedule/deleteSchedule';
// import PaginationNav from './components/PaginationNav';

const Schedule = () => {
  const [openedCollapseOne, setOpenedCollapseOne] = React.useState([
    false, false, false, false, false, false, false, false, false, false,
  ]);
  const [alert, setAlert] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editSchedule, setEditSchedule] = React.useState(false);
  const notificationAlertRef = React.useRef(null);

  const tableData = useSelector((state) => state.scheduleTable);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getScheduleTableData({page:1}));
    setInterval(() => {
      const location = window.location.href;
      if(location.includes("/admin/Schedule") && !open && !alert){
        dispatch(getScheduleTableData({page:tableData.page_information.current_page||1}));
      }
    }, 1*60*1000);
  }, [])

  const refScrollUp = useRef(null);


  const handleCollapse = (index) => {
    setOpenedCollapseOne([...openedCollapseOne.slice(0, index), !openedCollapseOne[index], ...openedCollapseOne.slice(index + 1)]);
  }
  const scheduleDelete = useSelector((state) => state.deleteSchedule);

  const handleDeleteSchedule = async (schedule) => {
    // API call
    hideAlert();
    await dispatch( await deleteScheduleData({...schedule}));
    await dispatch( await getScheduleTableData({page:1}));
  }


  React.useEffect(() => {
    if(scheduleDelete.loading==="succeeded"){
      successDelete();
      dispatch(deleteScheduleRemoveStateData());
      setLoading(false);
    }
    if(scheduleDelete.loading==="failed"){
      hideAlert();
      notify(scheduleDelete?.error?.data?.message,true);
      dispatch(deleteScheduleRemoveStateData());
      setLoading(false);
    }
    if(scheduleDelete.loading==="loading"){
      setLoading(true);
    }
  },[scheduleDelete.loading]);

  React.useEffect(() => {

    if(tableData.loading==="loading"){
      setLoading(true);
    }
    if(tableData.loading==="failed"){
      notify(tableData?.error?.data?.message,true);
      setLoading(false);
    }
    window.scrollTo(0, 0);

  },[tableData.loading]);

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Schedule hasa been deleted.
      </ReactBSAlert>
    );
  };


  const warningWithConfirmMessage = (schedule) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteSchedule(schedule)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this schedule!
      </ReactBSAlert>
    );
  };

  const notify = (msg,isError) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            {msg}
            {/* Welcome to <b>Black Dashboard React</b> - a beautiful premium admin
            for every web developer. */}
          </div>
        </div>
      ),
      type: isError?"danger":"success",
      icon:isError? "tim-icons icon-alert-circle-exc":"tim-icons icon-check-2",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };


  const hideAlert = () => {
    setAlert(null);
  };

  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen(!open);


  const handleOpenEditModal = async(schedule) => {
    await dispatch(getFacilityListData());
    await dispatch(getDeviceListData({facility_id:schedule?.facility_id}));
    await dispatch(getAreaListData({ id: schedule?.facility_id }));
    await dispatch(getProfileListData({user_device_id:schedule?.user_device_id}));
    setIsEdit(true);
    setEditSchedule(schedule);
    toggle();
  }

  const handleOpenAddModal = async() => {
    await dispatch(getFacilityListData());
    await dispatch(removeDeviceListState());
    await dispatch(removeProfileListState());
    setIsEdit(false);
    setEditSchedule(null);
    toggle();
  }

  const handlePageChange = async (page) => {
    await dispatch(getScheduleTableData({page}));
  }

  const dataTable = [
    {
      schedule: "Schedule for greenhouse",
      start:"01/09/2023",
      profile:"Silverfin Fish Hatchery",
      syncStatus:"Ok"
    },
    {
      schedule: "Silverfin Fish Hatchery",
      start:"02/08/2023",
      profile:"Silverfin Fish Hatchery",
      syncStatus:"Offline"     
    },
    {
      schedule: "Silverfin Fish Hatchery",
      start:"02/08/2023",
      profile:"Silverfin Fish Hatchery",
      syncStatus:"Waiting"     
    },
  ]


  // const IconsList = {
  //   "Temperature": { right: faTemperatureThreeQuarters, left: <>&deg;C</> },
  //   "Humidity": { right: faDroplet, left: <>%</> },
  //   "CO2": { right: faWind, left: <>PPM</> },
  //   "Light Brightness": { right: faSun, left: <>%</> },
  //   "configuration set": { right: faGear, left: <></> },
  //   "Light settings": { right: faSun, left: <></> },
  // }

  return (
    <>
      { }
      <div className='content ' id='scheduleTable' >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        {alert}
        <Card ref={refScrollUp}>
          <CardHeader headerTag="h6" className="bg-transparent border-bottom-0">
            <div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
              <div className="numbers">
                <p className=" px-2 mb-0 h4 text-white text-capitalize">existing Schedule</p>
              </div>
              <Button className="btn-simple border-white text-white d-flex add-btn justify-content-center align-items-center text-capitalize" color="primary" type="button" onClick={handleOpenAddModal}>
                <i className="tim-icons icon-simple-add pr-2" />Add New Schedule
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {
             tableData?.page_Data?.map((item, index) =>
              (
                <>
                  <Card className="facility-card" key={item?.schedule_name + index + "Card"}>
                    <CardHeader>
                      <Row>
                        <Col sm={12} md={3} lg={3}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-fit">
                              <FontAwesomeIcon icon={faA} size="xs" className='mb-0' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-2 font-weight-normal"> Schedule Name</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-fit pointer-event' >
                              <span className=" mb-0 text-white-50 fs-2 font-weight-light ">{item?.schedule_name}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={3} lg={3}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-fit ">
                              <FontAwesomeIcon icon={faClock} size="sm" className='mb-0' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">Start Date & Time</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-fit pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal ">{dayjs(item?.start_date).utc().local().format("DD/MM/YYYY HH:mm")}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={2} lg={2}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-fit ">
                              <FontAwesomeIcon icon={faUser} size="sm" className='mb-0' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-3 font-weight-normal">Profile</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-fit pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal ">{item?.profile_name}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={2} lg={2}>
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-fit ">
                              <FontAwesomeIcon icon={faCloud} size="sm" className='mb-0' style={{ color: "#ffffff", }} />
                              <p className=" px-2 mb-0 text-white fs-3 font-weight-normal text-nowrap">Sync Status</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-fit pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal ">{item?.status===null?"N/A":item?.status}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={2} lg={2}>
                          <div className="d-flex justify-content-around align-items-center py-3" >
                            <FontAwesomeIcon icon={faPenToSquare} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={(e) => { handleOpenEditModal(item) }} />
                            <FontAwesomeIcon icon={faTrashCan} size="xl" className='px-2 cursor-pointer' style={{ color: "#ffffff", }} onClick={() => warningWithConfirmMessage(item)} />
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                  </Card>
                </>
              )
              )
            }

            <>
              {(tableData.page_Data && tableData.page_Data.length > 0) ?
           tableData.page_information?.last_page > 1 ? <PaginationNav page_information={tableData?.page_information} setPage={handlePageChange}/>:<></>
            :<p className='h5'>There no existing Schedules here, press "Add new schedule" to create first one.</p>
            }
            </>
          </CardBody>

        </Card>
      </div>
      {open?
          <ScheduleAddAndEdit
            open={open}
            toggle={toggle}
            isEdit={isEdit}
            editSchedule={editSchedule} 
            notify={notify}
            />:<></>
        }
      {(scheduleDelete.loading==="loading" || tableData?.loading==="loading") && 
         <div className="loading-container">
            <div className="d-flex justify-content-center loading-div"><Spinner size="xl"/></div>
          </div>
          }
    </>
  )
}

export default Schedule

