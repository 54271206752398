import React from 'react'
import { Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const PaginationNav = ({ page_information, setPage }) => {

    const handlePageChange = (page) => {
        setPage(page);
    }


    // "last_page": 1,
    //         "current_page": 1,
    //         "previous_page": 0,
    //         "next_page": 0
    //     }

    const currentPage = page_information?.current_page;
    const lastPage = page_information?.last_page;
    const previousPage = page_information?.previous_page;
    const nextPage = page_information?.next_page;
    const arrayNew = Array.from({ length: lastPage }, (x, i) => i + 1);


    return (
        <>
            {/* <Card>
                <CardBody className='d-flex justify-content-center align-items-center'> */}
            <div className='d-flex justify-content-center align-items-center w-100'>
                <nav aria-label="Page navigation example">
                    {page_information.last_page > 7
                        ? (<Pagination>
                            {/* <PaginationItem>
                                <PaginationLink
                                    aria-label="Previous"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <span aria-hidden={true}>
                                        <i
                                            aria-hidden={true}
                                            className="tim-icons icon-double-left"
                                        />
                                    </span>
                                </PaginationLink>
                            </PaginationItem> */}

                            {currentPage > 2 && <PaginationItem>
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handlePageChange(1);
                                    }}
                                >
                                    1
                                </PaginationLink>
                            </PaginationItem>}

                            {currentPage > 3 && <PaginationItem>
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    ...
                                </PaginationLink>
                            </PaginationItem>}

                            {previousPage > 0 && <PaginationItem>
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handlePageChange(previousPage);
                                    }}
                                >
                                    {previousPage}
                                </PaginationLink>
                            </PaginationItem>}

                            <PaginationItem className="active">
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handlePageChange(currentPage);
                                    }}
                                >
                                    {currentPage}
                                </PaginationLink>
                            </PaginationItem>

                            {nextPage > 0 && <PaginationItem>
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handlePageChange(nextPage);
                                    }}
                                >
                                    {nextPage}
                                </PaginationLink>
                            </PaginationItem>}

                            {lastPage - currentPage > 2 && <PaginationItem>
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    ...
                                </PaginationLink>
                            </PaginationItem>}

                            {lastPage - nextPage > 0 && lastPage - currentPage > 1 && <PaginationItem>
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handlePageChange(lastPage);
                                    }}
                                >
                                    {lastPage}
                                </PaginationLink>
                            </PaginationItem>}

                            {/* <PaginationItem>
                                <PaginationLink
                                    aria-label="Next"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <span aria-hidden={true}>
                                        <i
                                            aria-hidden={true}
                                            className="tim-icons icon-double-right"
                                        />
                                    </span>
                                </PaginationLink>
                            </PaginationItem> */}
                        </Pagination>)
                        : (<Pagination>
                            {arrayNew.map((item, index) => {
                                return (<PaginationItem className={currentPage === item ? "active" : ""}>
                                    <PaginationLink
                                        href="#pablo"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handlePageChange(item);
                                        }}
                                    >
                                        {item}
                                    </PaginationLink>
                                </PaginationItem>)
                            })
                            }
                        </Pagination>)}
                </nav>
            </div>
            {/* </CardBody>
            </Card> */}
        </>
    )
}

export default PaginationNav