
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import NotificationAlert from "react-notification-alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import PaginationNav from './components/PaginationNav';
import AddDeviceModel from './components/AllModals/AddDeviceModel';
import { getDeviceTableData } from 'features/device/deviceTableSlice';
import AddDevicePairingModel from './components/AllModals/AddDevicePairingModel';
import { getAreaListData } from 'features/facility/areaListSlice';
import { getFacilityListData } from 'features/facility/facilityListSlice';
import { Await } from 'react-router-dom';
import { getDevicePairingsData } from 'features/device/devicePairingsSlice';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { deleteDevicePairingData } from 'features/device/deleteDevicePairingSlice';
import { deleteDevicePairingsRemoveStateData } from 'features/device/deleteDevicePairingSlice';
import secureLocalStorage from 'react-secure-storage';
import { GET_DEVICE_STATUS } from 'utils/utils';
import axios from 'axios';

const Device = () => {

  const deviceTable = useSelector((state) => state.deviceTable);
  const deleteDevicePairing = useSelector((state) => state.deleteDevicePairing);

  const [alert, setAlert] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isEditPair, setIsEditPair] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editDevice, setEditDevice] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const [deviceStatus, setDeviceStatus] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getDeviceTableData({ page: 1 }))
  }, []);

  const refScrollUp = useRef(null);

  React.useEffect(() => {
    if (deviceTable.loading === "loading") {
      setLoading(true);
    }
    if (deviceTable.loading === "failed") {
      notify(deviceTable?.error?.data?.message,true);
      setLoading(false);
    }
    if (deviceTable.loading === "succeeded") {
      setInterval(() => {
        const location = window.location.href;
        if( location.includes("/admin/Device")){
        deviceTable.page_Data?.map((device) => {
          getDeviceStatus(device);
        });
      }
      }, 5*60*1000);
      deviceTable.page_Data?.map((device) => {
        getDeviceStatus(device);
      });
      setLoading(false);
    }
    window.scrollTo(0, 0);

  }, [deviceTable.loading]);

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Device has been deleted.
      </ReactBSAlert>
    );
  };


  const warningWithConfirmMessage = (device) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteDevice(device)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this device!
      </ReactBSAlert>
    );
  };

  const handleDeleteDevice = async (device) => {
    // API call
    hideAlert();
    await dispatch(await deleteDevicePairingData({ ...device }));
  }
  const notify = (msg,isError) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            {msg}
            {/* Welcome to <b>Black Dashboard React</b> - a beautiful premium admin
            for every web developer. */}
          </div>
        </div>
      ),
      type: isError?"danger":"success",
      icon:isError? "tim-icons icon-alert-circle-exc":"tim-icons icon-check-2",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };


  React.useEffect(() => {
    if (deleteDevicePairing.loading === "succeeded") {
      successDelete();
      setLoading(false);
      dispatch(deleteDevicePairingsRemoveStateData());
    }
    if (deleteDevicePairing.loading === "failed") {
      hideAlert();
      notify(deleteDevicePairing?.error?.data?.message,true);
      setLoading(false);
      dispatch(deleteDevicePairingsRemoveStateData());
    }
    if (deleteDevicePairing.loading === "loading") {
      setLoading(true);
    }
  }, [deleteDevicePairing.loading]);

  const hideAlert = () => {
    setAlert(null);
  };

  const [open, setOpen] = React.useState(false);

  const toggle = () => {
    setOpen(!open)
  };
  const [openPair, setOpenPair] = React.useState(false);

  const togglePair = () => {
    setOpenPair(!openPair)
  };


  const handleOpenEditModal = async (device) => {
    await dispatch(getFacilityListData());
    setIsEdit(true);
    setEditDevice(device);
    toggle();
  }

  const handleOpenAddModal = async () => {
    await dispatch(getFacilityListData());
    setIsEdit(false);
    setEditDevice(null);
    toggle();
  }
  // const handleOpenEditPairModal = async (device) => {
  //   // await dispatch(getFacilityListData());
  //   await dispatch(getDevicePairingsData({id:device?.id}));
  //   setIsEditPair(true);
  //   setEditDevice(device);
  //   togglePair();
  // }

  const handleOpenAddPairModal = async (device) => {
    // await dispatch(getFacilityListData());

    await dispatch(getDevicePairingsData({ id: device?.id }));
    setIsEditPair(false);
    setEditDevice(device);
    togglePair();
  }

  const handlePageChange = async (page) => {
    await dispatch(getDeviceTableData({ page }));
  }


  const getDeviceStatus=async(item)=>{
    // const params={ "device_id" : item?.user_device_id }; 
    const token = secureLocalStorage.getItem("chart-token");    
    const config = {
        method: "get",
        url: GET_DEVICE_STATUS+"?deviceid="+item?.id,
      };
    try {
      const response = await axios(config);
      
      if(response?.data){
        setDeviceStatus((prevState) => [
          ...prevState.filter((device) => device?.id !== item?.id),
          { id: item?.id, status: response?.data?.status },
        ]);
      }
    } catch (err) {
      return console.log(err.response) 
    }
  }
  

  return (
    <>
      { }
      <div className='content ' id='facilityTable' >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        {alert}
        <Card ref={refScrollUp}>
          <CardHeader headerTag="h6" className="bg-transparent border-bottom-0">
            <div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
              <div className="numbers">
                <p className=" px-2 mb-0 h4 text-white text-capitalize">existing Device</p>
              </div>
              <Button className="btn-simple border-white text-white d-flex add-btn justify-content-center align-items-center text-capitalize" color="primary" type="button" onClick={handleOpenAddModal}>
                <i className="tim-icons icon-simple-add pr-2" />Add Device
                
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {
              deviceTable.page_Data?.map((item, index) =>
              (
                <>
                  <Card className="facility-card" key={item?.deviceName + index + "Card"}>
                    <CardHeader>
                      <Row>
                        <Col sm={12} md={5} lg={4} xl={4} xxl={2} >
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-100 ">
                              {/* <FontAwesomeIcon icon={faMapLocationDot} size="sm" className='mb-1' style={{ color: "#ffffff", }} /> */}
                              <p className=" px-1 mb-0 text-white fs-3 font-weight-normal text-nowrap text-truncate px-1">Device Serial</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-100 pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal text-nowrap text-truncate px-1">{item?.device?.serial_number || "--"}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={5} lg={4} xl={4} xxl={2} >
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-100 ">
                              {/* <FontAwesomeIcon icon={faMapLocationDot} size="sm" className='mb-1' style={{ color: "#ffffff", }} /> */}
                              <p className=" px-1 mb-0 text-white fs-3 font-weight-normal text-truncate px-1">Device Name</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-100 pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal text-truncate px-1">{item?.device_name || "--"}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={5} lg={4} xl={4} xxl={2} >
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-100 ">
                              {/* <FontAwesomeIcon icon={faMapLocationDot} size="sm" className='mb-1' style={{ color: "#ffffff", }} /> */}
                              <p className=" px-1 mb-0 text-white fs-3 font-weight-normal text-truncate px-1">Total Control Module</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-100 pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal text-truncate px-1">{item?.device?.device_modules?.length || "--"}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={5} lg={4} xl={4} xxl={2} >
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-100 ">
                              {/* <FontAwesomeIcon icon={faMapLocationDot} size="sm" className='mb-1' style={{ color: "#ffffff", }} /> */}
                              <p className=" px-1 mb-0 text-white fs-3 font-weight-normal text-truncate px-1">Facility Name</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-100 pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal text-truncate px-1">{item?.facility?.facility_name || "--"}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={5} lg={4} xl={4} xxl={2} >
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-100 ">
                              {/* <FontAwesomeIcon icon={faMapLocationDot} size="sm" className='mb-1' style={{ color: "#ffffff", }} /> */}
                              <p className=" px-1 mb-0 text-white fs-3 font-weight-normal text-truncate px-1">Device Model</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-100 pointer-event text-truncate'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal text-truncate px-1">{item?.device?.device_model || "--"}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={5} lg={4} xl={4} xxl={2} >
                          <div className="mb-2 d-flex flex-column w-100 justify-content-center align-items-start">
                            <div className="mb-2 d-flex justify-content-start align-items-center w-100 ">
                              {/* <FontAwesomeIcon icon={faMapLocationDot} size="sm" className='mb-1' style={{ color: "#ffffff", }} /> */}
                              <p className=" px-1 mb-0 text-white fs-3 font-weight-normal text-truncate px-1">Connection Status</p>
                            </div>
                            <div className='d-flex justify-content-start align-items-center w-100 pointer-event'>
                              <span className=" mb-0 text-white-50 fs-3 font-weight-normal text-truncate px-1">{deviceStatus.filter((device) => device?.id === item?.id)[0]?.status || "--"}</span> {" "}
                            </div>
                          </div>
                        </Col>
                        <Col md={12} className='w-md-50'>
                          <dov className="d-flex w-100 justify-content-end align-items-center mb-1">
                            <FontAwesomeIcon icon={faPenToSquare} size="xl" className='px-3 cursor-pointer' style={{ color: "#ffffff", }} onClick={(e) => { handleOpenEditModal(item) }} />
                            <FontAwesomeIcon icon={faTrashCan} size="xl" className='px-3 cursor-pointer' style={{ color: "#ffffff", }} onClick={(e) => { warningWithConfirmMessage(item) }} />
                            <Button className="mx-3 btn-simple border-white text-white d-flex justify-content-between align-items-center text-capitalize" color="primary" type="button"
                              onClick={(e) => handleOpenAddPairModal(item)}
                            >
                              {!item?.ControlModule?.total ? "Pairing" : "Pairing"}
                            </Button>
                          </dov>
                        </Col>
                      </Row>
                    </CardHeader>
                  </Card>
                </>
              )
              )
            }

            <>
              {(deviceTable.page_Data && deviceTable.page_Data.length > 0) ?
                deviceTable.page_information?.last_page > 1 ? <PaginationNav page_information={deviceTable?.page_information} setPage={handlePageChange} /> : <></>
                : <p className='h5'>There no existing device here, press "Add Model" to create first one.</p>
              }
            </>
          </CardBody>

        </Card>
      </div>
      {open ?
        <AddDeviceModel
          open={open}
          toggle={toggle}
          isEdit={isEdit}
          editDevice={editDevice}
          notify={notify}
        />
        : <></>
      }
      {openPair ?
        <AddDevicePairingModel
          open={openPair}
          toggle={togglePair}
          isEdit={isEditPair}
          editDevice={editDevice}
          notify={notify}
        />
        : <></>
      }
      {(deviceTable?.loading === "loading") &&
        <div className="loading-container">
          <div className="d-flex justify-content-center loading-div"><Spinner size="xl" /></div>
        </div>
      }
    </>
  )
}

export default Device



// Device


{/* <FontAwesomeIcon icon={faSatelliteDish} size="sm" style={{color: "#ffffff",}} Serial /> */ }
{/* <FontAwesomeIcon icon={faTowerBroadcast} size="sm" style={{color: "#ffffff",}} /> */ }
{/* <FontAwesomeIcon icon={faA} size="sm" style={{color: "#ffffff",}} /> name */ }
{/* <FontAwesomeIcon icon={faN} size="sm" style={{color: "#ffffff",}} /> no */ }


{/* <FontAwesomeIcon icon={faNetworkWired} size="sm" style={{color: "#ffffff",}} />  */ }




{/* <FontAwesomeIcon icon="fa-solid fa-tower-broadcast" /> */ }