
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";
import "assets/css/custom.css";
import CheckAuth from "layouts/Auth/CheckAuth";
import Login from "views/pages/Login";
// import Register from "views/pages/Register";

import { store } from 'app/store'
import { Provider } from 'react-redux'
import Register from "views/pages/Register";

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <Provider store={store}>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/login" element={<Login/>} />
      <Route path="/register" element={<Register/>} />
      
      <Route
        path="/admin/*"
        element={
          <CheckAuth>
            <AdminLayout />
          </CheckAuth>
        }
        />
      <Route path="*" element={<CheckAuth><Navigate to="/admin/dashboard" replace /></CheckAuth>} />
    </Routes>
  </BrowserRouter>
  </Provider>
);
