export const FRONTEND_BASE_URL = "";
// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || ""; // DEV
export const API_BASE_URL = "http://45.77.44.36:3000/api/v1/" || ""; // DEV
// export const API_BASE_URL ="http://192.168.1.56:3000/api/v1/" || ""; // DEV
// export const API_BASE_URL ="http://192.168.1.56:3001/api/v1/" || ""; // DEV
// export const API_BASE_URL = ""; // PRODUCTION
export const SOCKET_IO_URL = "";

export const LOGIN = API_BASE_URL + "login";
export const REGISTER = API_BASE_URL + "signup";

export const GET_PROFILE = API_BASE_URL + "profile";
export const GET_CHERT_DATA = API_BASE_URL + "dashboard";
export const GET_DASHBOARD = API_BASE_URL + "dashboard";
export const GET_FACILITY_DATA = API_BASE_URL + "facility/get-all";
export const ADD_FACILITY_DATA = API_BASE_URL + "facility/create";
export const EDIT_FACILITY_DATA = API_BASE_URL + "facility/edit/";
export const DELETE_FACILITY_DATA = API_BASE_URL + "facility/delete/";
export const GET_FACILITY_LIST = API_BASE_URL + "facility/get-list";
export const GET_AREA_LIST = API_BASE_URL + "facility/get-areas/";
export const FINED_SERIAL = API_BASE_URL + "device/search-serial-number";
export const GET_DEVICE_DATA = API_BASE_URL + "device/get-devices";
export const GET_DEVICE_STATUS = "http://api.evergrow.me:1880/connection_status";
export const ADD_DEVICE = API_BASE_URL + "device/claim-device";
export const EDIT_DEVICE = API_BASE_URL + "device/edit-device/";
export const GET_DEVICE_PAIRINGS = API_BASE_URL + "device/get-device-pairings/";
export const GET_DEVICE_LIST = API_BASE_URL + "device/get-devices-list";
export const ADD_DEVICE_PAIRINGS = API_BASE_URL + "device/pair-device/";
export const DELETE_DEVICE_PAIRINGS = API_BASE_URL + "device/delete-device-pairings/";
export const GET_PROFILE_DATA = API_BASE_URL + "profile/get-profiles";
export const ADD_PROFILE = API_BASE_URL + "profile/create-profile";
export const EDIT_PROFILE = API_BASE_URL + "profile/update-profile/";
export const DELETE_PROFILE = API_BASE_URL + "profile/delete-profile/";
export const GET_PROFILE_LIST = API_BASE_URL + "profile/profile-list";
export const GET_SCHEDULE_DATA = API_BASE_URL + "schedule/get-schedules";
export const ADD_SCHEDULE = API_BASE_URL + "schedule/add-schedule";
export const EDIT_SCHEDULE = API_BASE_URL + "schedule/update-schedule/";
export const DELETE_SCHEDULE = API_BASE_URL + "schedule/delete-schedule/";
export const GET_ALARM_DATA = API_BASE_URL + "alarm/get-alarms";
export const ADD_ALARM = API_BASE_URL + "alarm/create-alarm";
export const EDIT_ALARM = API_BASE_URL + "alarm/update-alarm/";
export const DELETE_ALARM = API_BASE_URL + "alarm/delete-alarm/";

export const GET_ALL_NOTIFICATION = API_BASE_URL + "notification/get-all-notifications";
export const DISMISS_NOTIFICATION = API_BASE_URL + "notification/dismiss-notification/";
