import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { GET_CHERT_DATA } from 'utils/utils';

export const getDashboardChartData = createAsyncThunk(
  'dashboardChart/getDashboardChartData',
  async (_,{rejectWithValue}) => {
    const token = secureLocalStorage.getItem("chart-token");
    const config = {
      method: "get",
      url: GET_CHERT_DATA,
      headers: {
        Authorization: token,
      },
    };
    try {
      const response = await axios(config)
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
)

const initialState = {
  chartData: null,
  loading: 'idle',
  error: null,
}

export const dashboardChartSlice = createSlice({
  name: 'dashboardChart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardChartData.pending, (state, action) => {
          state.loading = 'loading'
      })
      .addCase(getDashboardChartData.fulfilled, (state, action) => {
          state.loading = 'succeeded'
          state.chartData = action.payload.data
          state.error = null
      })
      .addCase(getDashboardChartData.rejected, (state, action) => {
          state.loading = 'failed'
          state.chartData = null
          state.error = action.payload
      })
  },
})


export default dashboardChartSlice.reducer;